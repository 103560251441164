import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View",
        to: { name: 'base.contacts.lists.details', params: { id: _ctx.id } }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-pin",
        label: "Pin"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Rename",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditListsModal(_ctx.listName, _ctx.tags)))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-duplicate",
        label: "Duplicate",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDuplicateListsModal(_ctx.listName, _ctx.tags)))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeleteListModal(_ctx.listName)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}