
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsListsFilter from '@/components/pages/contacts/lists/ContactsListsFilter.vue'
import ContactsListsTable from '@/components/pages/contacts/lists/ContactsListsTable.vue'
import ContactsListsEmpty from '@/components/pages/contacts/lists/ContactsListsEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import type { ContactList } from '@/definitions/contacts/lists/types'
import { listsFilters } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import UseContactsSegmentsOpenModal from '@/compositions/contacts/useContactsSegmentsOpenModal'

export default defineComponent({
  components: {
    ContactsListsEmpty,
    ContactsListsTable,
    ContactsListsFilter,
    PageContent,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openSegmentsModal } = UseContactsSegmentsOpenModal()

    const search = ref('')
    const { filters, isDefault, reset } = useFilters(listsFilters)
    const selected = ref<ContactList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'List name', value: 'list-name', width: '23%' },
      { text: 'Contacts', value: 'contacts', width: '10%' },
      { text: 'Tags', value: 'tags', hideSortable: true },
      { text: 'Created by', value: 'created-by', width: '16%' },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), width: '12%' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val), width: '12%' },
    ])
    const tableItems = ref<ContactList[]>(getTableData('contactsLists'))
    const filteredTableItems = computed<ContactList[]>(() => {
      return tableItems.value.filter((item) => item.listName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      openSegmentsModal,
      filters,
      isDefault,
      reset,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
