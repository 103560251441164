import { useModals } from '@/compositions/modals'

type UseContactsSegmentsOpenModal = () => {
  openSegmentsModal: () => void
  openEditSegmentsModal: (segmentName: string, tags: any[]) => void
  openDuplicateSegmentsModal: (segmentName: string) => void
  openDeleteSegmentModal: (listName: string) => void
  openDeleteSegmentsModal: (selected: any[]) => void
}

const useContactsSegmentsOpenModal: UseContactsSegmentsOpenModal = () => {
  const { openModal } = useModals()

  const openSegmentsModal = () => {
    openModal('contactsSegments')
  }
  const openEditSegmentsModal = (segmentName: string, tags: any[]) => {
    openModal('contactsSegments', {
      btnText: 'Save',
      modalTitle: 'Edit segment',
      name: segmentName,
      tags: tags,
    })
  }
  const openDuplicateSegmentsModal = (segmentName: string) => {
    openModal('singleInput', {
      modalTitle: 'Duplicate segment',
      label: 'New segment name',
      placeholder: 'Enter segment name',
      value: `${segmentName} (1)`,
      validators: 'required',
      btnText: 'Duplicate',
    })
  }

  const openDeleteSegmentModal = (segmentName: string) => {
    openModal('confirmation', {
      title: 'Delete segment',
      text: [
        {
          text: 'You are about to delete the segment ',
        },
        {
          text: segmentName,
          style: 'semi-bold',
        },
        {
          text: '. This action will not affect the contacts in this segment. \n\nAre you sure you want to delete the segment?',
        },
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  const openDeleteSegmentsModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('confirmation', {
        title: 'Delete segments',
        text: [
          {
            text: 'You are about to delete ',
          },
          {
            text: selected.length + ' segments',
            style: 'semi-bold',
          },
          {
            text: '. This action will not affect the contacts in those segments. \n\nAre you sure you want to delete those segments?',
          },
        ],
        btnText: `Delete ${selected.length} segments`,
        btnColor: 'error',
      })
    } else {
      openDeleteSegmentModal(selected[0].segmentName)
    }
  }

  return {
    openSegmentsModal,
    openEditSegmentsModal,
    openDuplicateSegmentsModal,
    openDeleteSegmentModal,
    openDeleteSegmentsModal,
  }
}

export default useContactsSegmentsOpenModal
