
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ContactsListsDropdown from '@/components/pages/contacts/lists/ContactsListsDropdown.vue'

export default defineComponent({
  components: {
    ContactsListsDropdown,
    TmStatus,
    TmEllipsis,
    TmBadges,
    TmTable,
    TmPerson,
    TmAvatar,
    Highlight,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
})
