
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    id: {
      type: String,
    },
    listName: {
      type: String,
    },
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    const { openEditListsModal, openDuplicateListsModal, openDeleteListModal } = useContactsListsOpenModal()

    return {
      openEditListsModal,
      openDuplicateListsModal,
      openDeleteListModal,
    }
  },
})
