
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'

import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'
import { useModals } from '@/compositions/modals'
import { tagsField } from '@/definitions/_general/_data/bulkFields'
import type { FiltersListType } from '@/definitions/shared/filters/types'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
    DropdownFilterAssignee,
    DropdownFilterRadio,
  },
  props: {
    selected: {
      type: Array,
    },
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
  emits: ['update:radio-value', 'update:model-value', 'reset'],
  setup(props) {
    const topFilterRef = ref()
    const search = ref('')
    const { openModal } = useModals()
    const { openDeleteListsModal, openListsModal } = useContactsListsOpenModal()

    const fieldsList = ref([
      tagsField,
    ])
    const tagsFieldsToEdit = ref([
      tagsField,
    ])
    const openEditTagsModal = () => {
      openModal('bulkEdit', {
        selected: props.selected,
        itemName: 'list',
        initialFields: tagsFieldsToEdit.value,
        fields: fieldsList.value,
        singleField: true,
      })
    }

    const openListMergeModal = () => {
      openModal('contactsListsMerge', {
        items: props.selected,
      })
    }

    return {
      topFilterRef,
      search,
      openModal,
      openListsModal,
      openEditTagsModal,
      openDeleteListsModal,
      openListMergeModal,
    }
  },
})
